const jquery = require("jquery");
window.$ = window.jQuery = jquery;
const Bowser = require("bowser");
import {Spinner} from 'spin.js'

const Speedtest = require('./speedtest');


const app = {

    init: function () {
        app.data = {};
        const browser = Bowser.getParser(window.navigator.userAgent);

        app.checkWebBrowser(browser);
        app.checkInternetServiceProvider();
        app.checkAdBlocker();
        app.addSpinner();
        app.checkVideoEnd();
        app.speedTestInit();
        app.setDataSpeedTest();
        app.checkOperatingSystem(browser);
        app.getUserName();

        //$('#checkPopUp').fadeOut('slow').fadeIn(5000);


    },
    getUserName: function () {

        var url_string = window.location.href;
        var url = new URL(url_string);
        var username = url.searchParams.get("username");
        var mail = url.searchParams.get("email");
        if (username) {
            $('#spinnerName').delay(10000).html('<i class="fa fa-check text-success " aria-hidden="true"></i>').removeClass();
            $('#username').text(username);
            $('#checkBoxName').hide().fadeIn(5000);

        } else {
            $('#spinnerName').html('<i class="fa fa-times text-danger" aria-hidden="true"></i>').removeClass()
            $('#checkBoxName').fadeOut('slow').fadeIn(5000);
        }

        app.data.user = {
            'username' : username,
            'email' : mail
        }
    },

    checkWebBrowser: function (browser) {

        $('#web-browser').text(browser.getBrowserName());
        $('#browser-version').text(browser.getBrowserVersion());

        if (browser.getBrowserVersion() && browser.getBrowserName()) {
            $('#spinnerWeb').html('<i class="fa fa-check text-success" aria-hidden="true"></i>').removeClass();
            $('#checkBoxWeb').fadeOut('slow').fadeIn(5000);
        } else {
            $('#spinnerWeb').html('<i class="fa fa-times text-danger" aria-hidden="true"></i>').removeClass();
            $('#checkBoxWeb').fadeOut('slow').fadeIn(5000);
        }



        app.data.browser = {
            'name': browser.getBrowserName(),
            'version': browser.getBrowserVersion()
        }

    },

    checkOperatingSystem: function (browser) {

        $('#system').text(browser.getOSName());
        $('#system-version').text(browser.getOSVersion());

        if (browser.getOSVersion() && browser.getOSName()) {
            $('#spinnerOp').html('<i class="fa fa-check text-success" aria-hidden="true"></i>\n').removeClass();
            $('#checkBoxOp').hide().fadeIn(5000);
        } else {
            $('#spinnerWeb').html('<i class="fa fa-times text-danger" aria-hidden="true"></i>').removeClass();
            $('#checkBoxOp').hide().fadeIn(5000);
        }

        app.data.os = {
            'name': browser.getOSName(),
            'version': browser.getOSVersion()
        }

    },

    checkInternetServiceProvider: function () {
        $.getJSON('https://ipinfo.io').done((response) => {

            var ip = response.ip;
            var isp = response.org.split(' ');

            $('#internet-service-provider').text(isp[1])

            if (isp[1]) {
                $('#spinnerIsp').html('<i class="fa fa-check text-success" aria-hidden="true"></i>').removeClass();
                $('#checkboxIsp').fadeOut('slow').fadeIn(5000);
            } else {
                $('#spinnerIsp').html('<i class="fa fa-times text-danger" aria-hidden="true"></i>').removeClass();
                $('#checkboxIsp').fadeOut('slow').fadeIn(5000);
            }

            app.data.internet = {
                'isp': isp[1]
            }

        });


    },

    checkAdBlocker: function () {
        // Function called if AdBlock is not detected
        function adBlockNotDetected() {
            $('#popUpBlocker').html('<span>Désactivé</span>');
            $('#spinPopUp').html('<i class="fa fa-check text-success" aria-hidden="true"></i>').removeClass();
            $('#spinPopUp').fadeOut('slow').fadeIn(5000);

            app.data.adBlock = {
                'adblock': 'Désactivé'
            }
        }

// Function called if AdBlock is detected
        function adBlockDetected() {
            $('#popUpBlocker').html('<span>Activé</span>');
            $('#spinPopUp').html('<i class="fa fa-times text-danger" aria-hidden="true"></i>\n</i>').removeClass();
            $('#spinPopUp').fadeOut('slow').fadeIn(5000);

            app.data.adBlock = {
                'adblock': 'Activé'
            }

        }


// We look at whether FuckAdBlock already exists.
        if (typeof fuckAdBlock !== 'undefined' || typeof FuckAdBlock !== 'undefined') {
            // If this is the case, it means that something tries to usurp are identity
            // So, considering that it is a detection
            adBlockDetected();
        } else {
            // Otherwise, you import the script FuckAdBlock
            var importFAB = document.createElement('script');
            importFAB.onload = function () {
                // If all goes well, we configure FuckAdBlock
                fuckAdBlock.onDetected(adBlockDetected)
                fuckAdBlock.onNotDetected(adBlockNotDetected);
            };
            importFAB.onerror = function () {
                // If the script does not load (blocked, integrity error, ...)
                // Then a detection is triggered
                adBlockDetected();
            };
            importFAB.integrity = 'sha256-xjwKUY/NgkPjZZBOtOxRYtK20GaqTwUCf7WYCJ1z69w=';
            importFAB.crossOrigin = 'anonymous';
            importFAB.src = 'https://cdnjs.cloudflare.com/ajax/libs/fuckadblock/3.2.1/fuckadblock.min.js';
            document.head.appendChild(importFAB);
        }
    },

    addSpinner: function () {

        var opts = {
            lines: 13, // The number of lines to draw
            length: 38, // The length of each line
            width: 17, // The line thickness
            radius: 45, // The radius of the inner circle
            scale: 0.1, // Scales overall size of the spinner
            corners: 1, // Corner roundness (0..1)
            speed: 1, // Rounds per second
            rotate: 0, // The rotation offset
            animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: '#f29400', // CSS color or array of colors
            fadeColor: 'transparent', // CSS color or array of colors
            top: '50%', // Top position relative to parent
            left: '50%', // Left position relative to parent
            shadow: '0 0 1px transparent', // Box-shadow for the lines
            zIndex: 2000000000, // The z-index (defaults to 2e9)
            className: 'spinner', // The CSS class to assign to the spinner
            position: 'absolute', // Element positioning
        };

        var target = document.querySelector('.spinnerName');
        var spinner = new Spinner(opts).spin(target);

        var targetUsername = document.querySelector('.spinner');
        var spinner1 = new Spinner(opts).spin(targetUsername);

        var targetVideo = document.querySelector('.spinnerVideo');
        var spinner2 = new Spinner(opts).spin(targetVideo);

        var targetSpeedTest = document.querySelector('.spinnerSpeedTest');
        var spinner3 = new Spinner(opts).spin(targetSpeedTest);
    },

    checkVideoEnd: function () {

        $('video').on('ended', (evt) => {
            $('#spinnerVideo').html('<i class="fa fa-check text-success" aria-hidden="true"></i>').removeClass();
            $('#videoInformation').fadeOut();
            $('video').fadeOut('slow').addClass('videoOver');
            var speedTestOver = $('#test').hasClass('speedTestOver');
            if(speedTestOver) {
                $('#results').html('<div id="sendResults" class="btn m-4 col-md-4">Send Results</div>').hide().fadeIn(3000);
                app.sendResults();
            }
        })

        app.data.video = {
            'video': 'ok'
        }

    },

    sendResults: function () {

        $("#sendResults").on('click', (function () {

            var data = app.data;
            $.ajax({
                url: './backend/send_mail.php', // Le nom du script a changé, c'est send_mail.php maintenant !
                type: 'POST', // Le type de la requête HTTP, ici devenu POST
                data: data

            });

            $("#sendResults").fadeOut('slow');
            $("#results").html('<p class="p-4 text-secondary text font-italic">Email envoyé à l\'infra <i class="fa fa-check text-success " aria-hidden="true"></i></p>');
        }));

    },

    setDataSpeedTest: function (downloadSpeed, uploadSpeed, ping) {
        app.data.speedtest = {
            download: downloadSpeed,
            upload: uploadSpeed,
            ping: ping
        }
    },

    speedTestInit: function () {
        //INITIALIZE SPEEDTEST

        var s = new Speedtest(); //create speedtest object
        s.onupdate = function (data) { //callback to update data in UI
            I("ip").textContent = data.clientIp;
            I("dlText").textContent = (data.testState == 1 && data.dlStatus == 0) ? "..." : data.dlStatus;
            I("ulText").textContent = (data.testState == 3 && data.ulStatus == 0) ? "..." : data.ulStatus;
            I("pingText").textContent = data.pingStatus;
            I("jitText").textContent = data.jitterStatus;
        }
        s.onend = function (aborted) { //callback for test ended/aborted
            $('#spinnerSpeed').html('<i class="fa fa-check text-success" aria-hidden="true"></i>').removeClass();
            var downloadSpeed = $('#dlText').text();
            var uploadSpeed = $('#ulText').text();
            var ping = $('#pingText').text();
            app.setDataSpeedTest(downloadSpeed, uploadSpeed, ping);
            var videoCheck = $( "#video" ).hasClass( "videoOver" );
            $('#test').addClass('speedTestOver');

                if(videoCheck){
                    $('#results').html('<div id="sendResults" class="btn m-4 col-md-4">Send Results</div>').hide().fadeIn(3000);
                    app.sendResults();
                }



            // I("startStopBtn").className=""; //show start button again
            if (aborted) { //if the test was aborted, clear the UI and prepare for new test
                initUI();
            }
        }

        function startStop() { //start/stop button pressed
            if (s.getState() == 3) {
                //speedtest is running, abort
                s.abort();
            } else {
                //test is not running, begin
                s.start();

                I("startStopBtn").className = "running";

            }
        }

//function to (re)initialize UI
        function initUI() {
            I("dlText").textContent = "";
            I("ulText").textContent = "";
            I("pingText").textContent = "";
            I("jitText").textContent = "";
            I("ip").textContent = "";
        }

        function I(id) {
            return document.getElementById(id);
        }

        s.start("start");

// document.querySelector('#startStopBtn').addEventListener('click', startStop);

        initUI();
    },

};

$(document).ready(app.init);
